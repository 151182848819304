import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Section, Box, Text } from '@rebeldotcom/components'
import { containerVariants } from '../../../utilities/panels'

const propTypes = {
  data: PropTypes.shape({
    bg: PropTypes.string,
    color: PropTypes.string,
    id: PropTypes.string.isRequired,
    theme: PropTypes.oneOf(['light', 'dark']),
    title: PropTypes.object,
  }).isRequired,
}

const Announcement = ({ data }) => {
  const { id, bg, theme, title } = data

  return (
    <Section
      bg={bg || containerVariants[theme || 'light'].bg}
      color={containerVariants[theme || 'light'].text}
      id={id}
      py={[1, 2]}
    >
      <Box
        alignItems="center"
        css={`
          gap: 1rem;
          text-align: center;
        `}
        flexDirection="column"
        justifyContent="center"
        mx={0}
        px={4}
        width="100%"
      >
        <Text fontSize={3} fontWeight="bold">
          {title.main?.text}
        </Text>
        <Text>{title.top.text}</Text>
      </Box>
    </Section>
  )
}

Announcement.propTypes = propTypes

export { Announcement }

export const announcementFragment = graphql`
  fragment AnnouncementPanel on Panel {
    theme
    color
    title {
      ...TitleProps
    }
  }
`
